import { defineStore } from 'pinia';
import { useFetchClient, useClient, useCsrf, useNotify, useRouter } from '#imports';

export interface IPlan {
  id: string;
  title: string;
  startsAt: string;
  finishesAt: string;
  createdAt: string;
  updatedAt: string;
}

export interface IResponsible {
  id: string;
  name: string;
}

export interface IDepartment {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface ITag {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface IObjective {
  id: string;
  title: string;
  responsibles: Array<IResponsible>;
  KeyResult: Array<object>;
  createdAt: string;
  updatedAt: string;
}

export interface IKeyResult {
  title: string;
  responsible: string;
  department: string;
  description: string;
  status: 'waiting' | 'execution' | 'paused' | 'stuck' | 'finished';
  dueAt: string;
  tags: Array<string>;
}

export interface IOkrsState {
  plans: Array<IPlan>;
  objectives: Array<IObjective>;
  departments: Array<IDepartment>;
  responsibles: Array<IResponsible>;
  tags: Array<ITag>;
  keyResult: IKeyResult;
}

export const useOkrsStore = defineStore('okrsStore', {
  state: (): IOkrsState => ({
    plans: [],
    objectives: [],
    departments: [],
    responsibles: [],
    keyResult: {
      title: '',
      responsible: '',
      department: '',
      description: '',
      status: 'waiting',
      dueAt: '',
      tags: [],
    },
    tags: [],
  }),
  getters: {
    currentPlan: (state): { name: string; params?: { plan: string } } | null => {
      if (!state.plans?.length) {
        useNotify('Nenhum plano foi encontrado!');

        return;
      }

      const plan = state.plans[0];

      return { name: 'okrs-plans-plan', params: { plan: plan.id } };
    },
  },
  actions: {
    async start(planId: string) {
      const [{ data: objectives }, { data: departments }, { data: responsibles }] = await Promise.all([
        useFetchClient(`/api/okrs/plans/${planId}/objectives`),
        useFetchClient('/api/okrs/departments'),
        useFetchClient('/api/okrs/responsibles'),
      ]);

      this.objectives = objectives.value;
      this.departments = departments.value;
      this.responsibles = responsibles.value;
    },
    async createPlan(body: object) {
      const { csrf } = useCsrf();
      const router = useRouter();

      try {
        const response = await useClient('/api/okrs/plans', {
          method: 'POST',
          body,
          headers: { 'csrf-token': csrf },
        });

        if (!response.id) return;

        useNotify('Novo plano adicionado!');

        this.plans.push(response);

        await this.start(response.id);

        router.push({ name: 'okrs-plans-plan', params: { plan: response.id } });
      } catch (e) {}
    },
    async createObjective(planId: string, body: object) {
      const { csrf } = useCsrf();

      const response = await useClient(`/api/okrs/plans/${planId}/objectives`, {
        method: 'POST',
        body,
        headers: { 'csrf-token': csrf },
      });

      if (!response.id) return;

      useNotify({ message: 'Novo objetivo adicionado!', type: 'success' });

      this.objectives.push(response);
    },
    async updateObjective({ planId, objectiveId }: { planId: string; objectiveId: string }, body: object) {
      const { csrf } = useCsrf();

      const response = await useClient(`/api/okrs/plans/${planId}/objectives/${objectiveId}`, {
        method: 'PUT',
        body,
        headers: { 'csrf-token': csrf },
      });

      if (!response.id) return;

      useNotify({ message: 'Objetivo Atualizado!', type: 'success' });
    },
    async deleteObjective({ planId, objectiveId }: { planId: any; objectiveId: any }) {
      const { csrf } = useCsrf();

      const response = await useClient(`/api/okrs/plans/${planId}/objectives/${objectiveId}`, {
        method: 'DELETE',
        headers: { 'csrf-token': csrf },
      });

      if (response) {
        useNotify({ message: 'Objetivo removido!', type: 'success' });

        this.objectives.splice(
          this.objectives.findIndex((o) => o.id === objectiveId),
          1
        );
      }
    },
    viewKeyResult({ planId, objectiveId, KeyResultId }: { planId: any; objectiveId: any; KeyResultId: any }) {
      return useFetchClient(`/api/okrs/plans/${planId}/objectives/${objectiveId}/keyResults/${KeyResultId}`);
    },
    async createKeyResult(ids: { planId: any; objectiveId: any }, body: object) {
      const { csrf } = useCsrf();

      const response = await useClient(`/api/okrs/plans/${ids.planId}/objectives/${ids.objectiveId}/keyResults`, {
        method: 'POST',
        body,
        headers: { 'csrf-token': csrf },
      });

      if (!response.id) return;

      useNotify({ message: 'Nova tarefa adicionada!', type: 'success' });

      this.objectives = await useClient(`/api/okrs/plans/${ids.planId}/objectives`);
    },
    async updateKeyResult(
      { planId, KeyResultId, objectiveId }: { planId: any; objectiveId: any; KeyResultId: any },
      body: object
    ) {
      const { csrf } = useCsrf();

      const response = await useClient(
        `/api/okrs/plans/${planId}/objectives/${objectiveId}/keyResults/${KeyResultId}`,
        {
          method: 'PUT',
          body,
          headers: { 'csrf-token': csrf },
        }
      );

      if (!response.id) return;

      useNotify({ message: 'Tarefa atualizada!', type: 'success' });

      this.objectives = await useClient(`/api/okrs/plans/${planId}/objectives`);
    },
    async deleteKeyResult({ planId, objectiveId, KeyResultId }: { planId: any; objectiveId: any; KeyResultId: any }) {
      const { csrf } = useCsrf();

      const response = await useClient(
        `/api/okrs/plans/${planId}/objectives/${objectiveId}/keyResults/${KeyResultId}`,
        {
          method: 'DELETE',
          headers: { 'csrf-token': csrf },
        }
      );

      if (response) {
        useNotify({ message: 'Tarefa removida!', type: 'success' });

        this.objectives = await useClient(`/api/okrs/plans/${planId}/objectives`);
      }

      return response;
    },
    async changeStatusKeyResult(
      {
        planId,
        objectiveId,
        KeyResultId,
      }: {
        planId: any;
        objectiveId: any;
        KeyResultId: any;
      },
      status: string
    ) {
      const { csrf } = useCsrf();

      const response = await useClient(
        `/api/okrs/plans/${planId}/objectives/${objectiveId}/keyResults/${KeyResultId}/status`,
        {
          method: 'PUT',
          headers: { 'csrf-token': csrf },
          body: { status },
        }
      );

      if (!response.id) return;

      useNotify({ message: 'Status Atualizado!', type: 'success' });

      this.objectives = await useClient(`/api/okrs/plans/${planId}/objectives`);
    },
  },
});
